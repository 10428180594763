import axios from 'axios'

const BASE_URL = process.env.VUE_APP_BASE_URL_OSIRIS

const apiKey = sessionStorage.getItem('o_api_key')
const accessToken = sessionStorage.getItem('o_access')

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    api_key: apiKey,
    Authorization: `Bearer ${accessToken}`,
  },
})
