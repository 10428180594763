import permissions from '@/enums/permissions';

const liquidationRouter = [
    {
        path: '/liquidacao',
        name: 'liquidation',
        component: () => import('@/views/pages/Liquidation/Liquidation.vue'),
        meta: {

            permissions: permissions.liquidation,
        },
    },
];

export default liquidationRouter;
