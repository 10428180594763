const accessRouter = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/access/Login.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/esqueci-senha',
        name: 'forgot-password',
        component: () => import('@/views/pages/access/ForgotPassword.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/redefinir-senha/:hash',
        name: 'reset-password',
        component: () => import('@/views/pages/access/ResetPassword.vue'),
        meta: {
            layout: 'full',
        },
    },
    {
        path: '/perfil',
        name: 'profile',
        component: () => import('@/views/pages/AccountSetting/AccountSetting.vue'),
    },
];

export default accessRouter;
