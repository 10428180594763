import VueCompositionAPI from '@vue/composition-api';
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue';
import VueTheMask from 'vue-the-mask';
import Vue from 'vue';
import money from 'vuejs-money';
import VueJsonToCsv from 'vue-json-to-csv';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {
    formatDate, formatTaxId, formatValue, formatPercent,
} from '@/utils';
import inactivity from '@/mixins/inactivity';

import App from './App.vue';
import router from './router';
import store from './store';

// Global Components
import './global-components';
// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/sweet-alerts';
import '@/libs/toastification';

import { callLoading } from './service/utilities';

Vue.config.devtools = false;
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.mixin(inactivity);
Vue.use(ModalPlugin);
Vue.use(VueTheMask);
// Composition API
Vue.use(VueCompositionAPI);

Vue.use(money);

Vue.use(VueJsonToCsv);

Vue.filter('date', date => formatDate(date));
Vue.filter('date_hour', date => formatDate(date, 'dd/MM/yyyy HH:mm'));
Vue.filter('tax_id', taxId => formatTaxId(taxId));
Vue.filter('value', value => formatValue(value));
Vue.filter('percent', value => formatPercent(value));

Vue.mixin(inactivity);

Vue.mixin({
    data() {
        return {
            money: {
                decimal: '.',
                thousands: '',
                prefix: 'R$ ',
                precision: 2,
                masked: false,
            },
        };
    },

    methods: {
        modalError(text) {
            this.$swal({
                icon: 'error',
                text,
                customClass: {
                    confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
            });
        },

        modalSuccess(text) {
            this.$swal({
                icon: 'success',
                text,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            });
        },

        modal(text) {
            this.$swal({
                text,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            });
        },

        callLoading(loading) {
            callLoading(loading);
        },

        toast(msg, icon, variant) {
            this.$toast(
                {
                    component: ToastificationContent,
                    props: {
                        title: msg,
                        icon,
                        variant,
                    },
                },
                {
                    position: 'top-right',
                },
                {
                    timeout: 5000,
                },
            );
        },

        async confirmAnAction(message) {
            try {
                const response = await this.$bvModal
                    .msgBoxConfirm(message, {
                        cancelVariant: 'outline-secondary',
                        centered: true,
                        okTitle: 'Sim',
                        cancelTitle: 'Não',
                    });
                return response;
            } catch {
                return false;
            }
        },

        groupBy(data, key) {
            return data.reduce((acc, item) => {
                acc[item[key]] = acc[item[key]] || [];
                acc[item[key]].push(item);

                return acc;
            }, {});
        },
    },
});

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = true;

store.dispatch('user/fetch').finally(() => new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app'));
