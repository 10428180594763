import permissions from '@/enums/permissions';

export default [
    {
        path: '/cedente',
        name: 'assignor',
        component: () => import('@/views/pages/Assignor/Assignor.vue'),
        meta: {
            permissions: permissions.assignors,

        },
    },
    {
        path: '/cedente/:id',
        name: 'group-assignor',
        component: () => import('@/views/pages/Assignor/Group.vue'),
        meta: {
            permissions: permissions.assignors,

        },
    },
];
