import { osiris } from '../api';
import Osiris from '../base/osiris';

import methods from '@/enums/methods';

class UserOsirisService extends Osiris {
    api = osiris

    async createUserAndNewGroup(request) {
        try {
            return await this.api.post('user', request);
        } catch ({ response }) {
            return response;
        }
    }

    login() {
        return this.fetch({
            url: 'login',
            data: {
                email: 'admin.ti@soulpay.com.br',
                password: 'soulpaydev',
            },
            method: methods.POST,
        });
    }
}

export default new UserOsirisService();
