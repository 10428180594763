import permissions from '@/enums/permissions';

export default [
    {
        path: '/fgts/download',
        name: 'fgts-download',
        component: () => import('@/views/pages/Fgts/GetFiles.vue'),
        meta: {
            permissions: permissions.fgtsDownload,
        },
    }, {
        path: '/fgts/importar',
        name: 'fgts-import',
        component: () => import('@/views/pages/Fgts/Import.vue'),
        meta: {
            permissions: permissions.fgtsImport,
        },
    },
];
