import permissions from '@/enums/permissions';

const fundsRouter = [
    {
        path: '/fundos',
        name: 'register-funds',
        component: () => import('@/views/pages/Funds/RegisterFunds.vue'),
        meta: {
            permissions: permissions.registerFunds,
        },
    },
    {
        path: '/laminas',
        name: 'register-sheet',
        component: () => import('@/views/pages/Funds/RegisterSheet.vue'),
        meta: {
            permissions: permissions.registerFunds,
        },
    },
    {
        path: '/artigo-blog',
        name: 'register-blog-article',
        component: () => import('@/views/pages/Funds/RegisterBlogArticle.vue'),
        meta: {
            permissions: 97,
        },
    },
];

export default fundsRouter;
