import permissions from '@/enums/permissions';

const conciliationRouter = [
    {
        path: '/conciliacao',
        name: 'conciliation',
        component: () => import('@/views/pages/Conciliation/Conciliation.vue'),
        meta: {

            permissions: permissions.conciliation,
        },
    },

    {
        path: '/conciliacao/finalizada',
        name: 'conciliation-finished',
        component: () => import('@/views/pages/Conciliation/Finished.vue'),
        meta: {
            permissions: permissions.finishedConciliation,
        },
    },
];

export default conciliationRouter;
