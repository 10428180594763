import permissions from '@/enums/permissions';

const accountScrowRoute = [
    {
        path: '/conta-escrow',
        name: 'view-account-escrow',
        component: () => import('@/views/pages/AccountEscrow/View.vue'),
        meta: {
            permissions: permissions.accountEscrow,
        },
    },
];

export default accountScrowRoute;
