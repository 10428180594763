import axios from 'axios';

import Ramses from '../base/ramses';

import UserOsirisService from '../credit_platform/access';

import methods from '@/enums/methods';

axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('token')}`;

class UserService extends Ramses {
    authenticate() {
        return this.fetch({
            url: 'users/authenticate',
            method: methods.POST,
        });
    }

    me() {
        return this.fetch({
            url: 'users/me',
        });
    }

    logout() {
        return this.fetch({
            url: 'users/logout',
            method: methods.POST,
        });
    }

    forgotPassword({ email }) {
        return this.fetch({
            data: { email },
            url: 'users/recover-password',
            method: methods.POST,
        });
    }

    changePassword({ password, new_password }) {
        return this.fetch({
            data: { password, new_password },
            method: methods.PUT,
            url: 'users/change-password',
        });
    }

    verifyHash(hash) {
        return this.fetch({
            url: `users/verify-hash/${hash}`,
        });
    }

    resetPassword({ new_password }, hash) {
        return this.fetch({
            data: { new_password },
            url: `users/reset-password/${hash}`,
            method: methods.PUT,
        });
    }

    async signIn(email, password) {
        try {
            const [responseLogin, responseOsiris] = await Promise.all([
                this.fetch({
                    url: 'users/login',
                    data: {
                        email,
                        password,
                    },
                    method: methods.POST,
                }),
                UserOsirisService.login(),
            ]);

            const { token: tokenAccessToken, api_key: apiKey } = responseOsiris.data;

            const {
                token,
            } = responseLogin.data;

            sessionStorage.setItem('token', token);

            if (apiKey) {
                sessionStorage.setItem('o_access', tokenAccessToken);
                sessionStorage.setItem('o_api_key', apiKey);
            }

            return responseLogin;
        } catch ({ response }) {
            return response;
        }
    }
}

export default new UserService();
