import permissions from '@/enums/permissions';

const customerStatementRouter = [
    {
        path: '/extrato-cliente',
        name: 'customer-statement',
        component: () => import('@/views/pages/CustomerStatement/CustomerStatement.vue'),
        meta: {
            permissions: permissions.customerStatement,
        },
    },
    {
        path: '/lancamento-extrato',
        name: 'statement-posting',
        component: () => import('@/views/pages/CustomerStatement/StatementPosting.vue'),
        meta: {
            permissions: permissions.statementPosting,
        },
    },
];

export default customerStatementRouter;
