const { default: Ramses } = require('../base/ramses');

class MenuService extends Ramses {
    index() {
        return this.fetch({
            url: '/menu',
        });
    }
}

export default new MenuService();
