export default {
  namespaced: true,
  state: {
    id: null,
    opt_in_id: null,
    tax_id: null,
  },
  getters: {
    merchantId: state => state.id,
    optInId: state => state.opt_in_id,
    taxId: state => state.tax_id,
  },
  mutations: {
    SET_MERCHANT(state, payload) {
      state.id = payload
    },

    SET_OPTIN(state, payload) {
      state.opt_in_id = payload
    },

    SET_TAX_ID(state, payload) {
      state.tax_id = payload
    }
  },
  actions: {},
}
