export default {
    data() {
        return {
            inactivityTimeout: 14 * 60 * 1000,
            lastInteractionTime: new Date().getTime(),
            inactivityInterval: null,
        };
    },
    methods: {
        updateLastInteractionTime() {
            this.lastInteractionTime = new Date().getTime();
        },
        checkInactivity() {
            const currentTime = new Date().getTime();
            const elapsedTime = currentTime - this.lastInteractionTime;

            if (elapsedTime > this.inactivityTimeout) {
                this.handleInactivity();
            }
        },
        startInactivityCheck() {
            this.inactivityInterval = setInterval(this.checkInactivity, 60 * 1000);
            this.updateLastInteractionTime();
        },
        handleInactivity() {
            if (this.$router?.history?.current?.path !== '/relatorio/metas') { this.$router.push('/login'); sessionStorage.clear(); }

            clearInterval(this.inactivityInterval);
        },
    },
    mounted() {
        document.addEventListener('mousemove', this.updateLastInteractionTime);
        document.addEventListener('keydown', this.updateLastInteractionTime);
        document.addEventListener('click', this.updateLastInteractionTime);

        this.startInactivityCheck();
    },
    beforeDestroy() {
        clearInterval(this.inactivityInterval);
        document.removeEventListener('mousemove', this.updateLastInteractionTime);
        document.removeEventListener('keydown', this.updateLastInteractionTime);
        document.removeEventListener('click', this.updateLastInteractionTime);
    },
};
