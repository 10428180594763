import permissions from '@/enums/permissions';

const srcRouter = [
    {
        path: '/scr',
        name: 'scr',
        component: () => import('@/views/pages/scr/scr.vue'),
        meta: {
            permissions: permissions.scr,
        },
    },
];

export default srcRouter;
