import permissions from '@/enums/permissions';

export default [
    {
        path: '/clientes-negativados',
        name: 'negative-clients',
        component: () => import('@/views/pages/Negatives/NegativeClients.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.negativeClients,
        },
    },
    {
        path: '/clientes-negativados/painel',
        name: 'negative-clients-report',
        component: () => import('@/views/pages/Negatives/Report.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dashboardNegativeClients,
        },
    },
];
