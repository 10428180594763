import permissions from '@/enums/permissions';

const taxRouter = [
    {
        path: '/taxa/tef',
        name: 'tax-tef',
        component: () => import('@/views/pages/Tax/Tef.vue'),
        meta: {
            permissions: permissions.tefTax,
        },
    },
];

export default taxRouter;
