import Vue from 'vue';
import VueRouter from 'vue-router';

import AuthMiddleware from './middlewares/auth';

import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes,
});

router.beforeEach((to, from, next) => AuthMiddleware.execute(to, from, next));

export default router;
