import permissions from '@/enums/permissions';

const powerBiRouter = [
    {
        path: '/painel/contribuicao',
        name: 'contribuition-dashboard',
        component: () => import('@/views/pages/reports/PowerBI/Contribuitions.vue'),
        meta: {
            permissions: permissions.dashboardContribuition,
        },
    },
    {
        path: '/painel/rotina',
        name: 'routine-dashboard',
        component: () => import('@/views/pages/reports/PowerBI/Routine.vue'),
        meta: {
            permissions: permissions.dashboardRoutine,
        },
    },
    {
        path: '/painel/diretoria',
        name: 'bi',
        component: () => import('@/views/pages/reports/PowerBI/Directory.vue'),
        meta: {
            permissions: permissions.dashboardDirectory,
        },
    },

    {
        path: '/relatorio/conciliacao',
        name: 'report-conciliation',
        component: () => import('@/views/pages/reports/PowerBI/Conciliation.vue'),
        meta: {
            permissions: permissions.dashboardConciliation,
        },
    },
    {
        path: '/transactions',
        name: 'transactions',
        component: () => import('@/views/pages/reports/PowerBI/Transactions.vue'),
        meta: {
            permissions: permissions.dashboardTransactions,
        },
    },
    {
        path: '/relatorio/transacoes-digitadas',
        name: 'dashboard-typed-transactions',
        component: () => import('@/views/pages/reports/PowerBI/RiskIndicators.vue'),
        meta: {
            permissions: permissions.dashboardRiskIndicators,
        },
    },
    {
        path: '/relatorio/transacoes-atipicas',
        name: 'dashboard-atypical-transactions',
        component: () => import('@/views/pages/reports/PowerBI/AtypicalTransactions.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dashboardAtypicalTransactions,
        },
    },
    {
        path: '/conciliacao-de-recebimentos',
        name: 'receipts-conciliation',
        component: () => import('@/views/pages/reports/PowerBI/ReceiptsConciliation.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dashboardReceiptsConciliation,
        },
    },
    {
        path: '/relatorio/scr',
        name: 'account-payable',
        component: () => import('@/views/pages/reports/PowerBI/SCR.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dashboardAccountPayable,
        },
    },
    {
        path: '/relatorio/orcamento',
        name: 'report-budget',
        component: () => import('@/views/pages/reports/PowerBI/Budget.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dashboardBudget,
        },
    },
    {
        path: '/relatorio/transacoes',
        name: 'report-transactions-soulpay',
        component: () => import('@/views/pages/reports/PowerBI/Transactions.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dashboardTransactionsSoulpay,
        },
    },
    {
        path: '/relatorio/antecipacao-de-recebiveis',
        name: 'report-receivables',
        component: () => import('@/views/pages/reports/PowerBI/Receivables.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dashboardReceivables,
        },
    },
    {
        path: '/relatorio/metas',
        name: 'goals-dashboard',
        component: () => import('@/views/pages/reports/PowerBI/Internal.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dashboardGoals,
            layout: 'full',
        },
    },

    {
        path: '/relatorio/DRE',
        name: 'report-dre',
        component: () => import('@/views/pages/reports/PowerBI/DRE.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dashboardDRE,
        },
    },

    {
        path: '/relatorio/cadastro-oportunidades',
        name: 'report-register-opportunities',
        component: () => import('@/views/pages/reports/PowerBI/RegisterOpportunities.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dashboardRegisterOpportunites,
        },
    },

    {
        path: '/relatorio/estoque-de-transacoes',
        name: 'report-inventory-transactions',
        component: () => import('@/views/pages/reports/PowerBI/TransactionsInventory.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dashboardTransactionsInventory,
        },
    },

    {
        path: '/relatorio/planilha-cedente',
        name: 'report-cedent-sheet',
        component: () => import('@/views/pages/reports/PowerBI/CedentSheet.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dashboardCedentSheet,
        },
    },
    {
        path: '/relatorio/pagamento-por-data',
        name: 'reporta-payment-by-date',
        component: () => import('@/views/pages/reports/PowerBI/PaymentDateValidation.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dateDashboard,
        },
    },
    {
        path: '/relatorio/atendimento-ativo',
        name: 'report-active-service',
        component: () => import('@/views/pages/reports/PowerBI/ActiveService.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.activeService,
        },
    },
    {
        path: '/relatorio/orcamento',
        name: 'report-budget-controllership',
        component: () => import('@/views/pages/reports/PowerBI/BudgetControllership.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.dashboardBudgetController,
        },
    },
];

export default powerBiRouter;
