import axios from 'axios'

const BASE_URL = process.env.VUE_APP_BASE_URL

const TIME_IN_MINUTE = 10 * 60 * 1000 // 10 minutes

const token = sessionStorage.getItem('token')

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${token}`,
    'Api-key': process.env.VUE_APP_API_KEY,
  },
  timeout: 0,
})
