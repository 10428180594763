import permissions from '@/enums/permissions';

const ecommerceRouter = [
    {
        path: '/ecommerce',
        name: 'e-commerce',
        component: () => import('@/views/pages/ecommerce/EcommerceComp.vue'),
        meta: {
            permissions: permissions.ecommerce,
        },
    },
    {
        path: '/ecommerce/edit-ecommerce/:id',
        name: 'edit-ecommerce',
        component: () => import('@/views/pages/ecommerce/EditEcommerceUser.vue'),
        meta: {
            permissions: permissions.editEcommerce,
        },
    },
    {
        path: '/ecommerce/novo-grupo',
        name: 'e-commerce-new-group',
        component: () => import('@/views/pages/ecommerce/Groups/NewGroup.vue'),
        meta: {
            permissions: permissions.ecommerce,
        },
    },
    {
        path: '/ecommerce/grupo/:id',
        name: 'e-commerce-update-group',
        component: () => import('@/views/pages/ecommerce/Groups/UpdateGroup.vue'),
        meta: {
            permissions: permissions.ecommerce,
        },
    },
];

export default ecommerceRouter;
