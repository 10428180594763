import permissions from '@/enums/permissions';

const messageRouter = [
    {
        path: '/whatsapp/grupos',
        name: 'whatsapp-groups',
        component: () => import('@/views/pages/Message/Groups/Groups.vue'),
        meta: {
            permissions: permissions.whatsappGroup,
            requiresAuth: true,
        },
    },
    {
        path: '/whatsapp/mensagens',
        name: 'whatsapp-message',
        component: () => import('@/views/pages/Message/SendMessage/Message.vue'),
        meta: {
            permissions: permissions.whatsappMessage,
            requiresAuth: true,
        },
    },
];

export default messageRouter;
