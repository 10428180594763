import permissions from '@/enums/permissions';

export default [
    {
        path: '/balanco/importar',
        name: 'import-balance',
        component: () => import('@/views/pages/Balance/File/ImportFile.vue'),
        meta: {
            permissions: permissions.score,
        },
    },

    {
        path: '/balanco/visualizar',
        name: 'view-balance',
        component: () => import('@/views/pages/Balance/ViewBalance/ViewBalance.vue'),
        meta: {
            permissions: permissions.score,

        },
    },

    {
        path: '/balanco/visualizar/:id',
        name: 'view-balance-id',
        component: () => import('@/views/pages/Balance/ViewBalance/ViewBalance.vue'),
        meta: {
            permissions: permissions.score,
        },
    },

    {
        path: '/balanco/visualizar/resumo/:id',
        name: 'view-balance-summary',
        component: () => import('@/views/pages/Balance/Summary/Summary.vue'),
        meta: {
            permissions: permissions.score,
            layout: 'full',
        },
    },

    {
        path: '/balanco/visualizar/reanalise/:id',
        name: 'view-balance-summary',
        component: () => import('@/views/pages/Balance/Reanalyze/Reanalyze.vue'),
        meta: {
            permissions: permissions.score,
            layout: 'full',
        },
    },
];
