import permissions from '@/enums/permissions';

const creditPlatformRouter = [
    {
        path: '/plataforma-credito/grupo',
        name: 'group',
        component: () => import('@/views/pages/CreditPlatform/Group.vue'),
        meta: {

            pageTitle: 'Inicio',
            permissions: permissions.manageCreditPlatform,

        },
    },
    {
        path: '/plataforma-credito/extrato',
        name: 'extract',
        component: () => import('@/views/pages/CreditPlatform/Extract.vue'),
        meta: {

            pageTitle: 'Inicio',
            permissions: permissions.manageCreditPlatform,

        },
    },
    {
        path: '/plataforma-credito/grupo/:id',
        name: 'edit-group',
        component: () => import('@/views/pages/CreditPlatform/CreateGroup.vue'),
        meta: {
            permissions: permissions.manageCreditPlatform,

        },
    },
    {
        path: '/plataforma-credito/grup/criar',
        name: 'create-group',
        component: () => import('@/views/pages/CreditPlatform/CreateGroup.vue'),
        meta: {
            permissions: permissions.manageCreditPlatform,
        },
    },

    {
        path: '/plataforma-credito/usuario/editar/:id',
        name: 'edit-creditplatform-user',
        component: () => import('@/views/pages/CreditPlatform/CreateUser.vue'),
        meta: {
            permissions: permissions.manageCreditPlatform,
        },
    },
    {
        path: '/plataforma-credito/usuario/criar',
        name: 'create-creditplatform-user',
        component: () => import('@/views/pages/CreditPlatform/CreateUser.vue'),
        meta: {

            permissions: permissions.manageCreditPlatform,

        },
    },
    {
        path: '/plataforma-credito/taxa-padrao',
        name: 'default-rate',
        component: () => import('@/views/pages/CreditPlatform/DefaultRates.vue'),
        meta: {

            permissions: permissions.manageCreditPlatform,

        },
    },
    {
        path: '/plataforma-credito/taxas',
        name: 'rate',
        component: () => import('@/views/pages/CreditPlatform/Rates.vue'),
        meta: {

            permissions: permissions.manageCreditPlatform,

        },
    },

    // {
    //     path: '/plataforma-credito/novo-estabelecimento',
    //     name: 'novo-estabelecimento',
    //     component: () => import('@/views/pages/CreditPlatform/NewMerchant.vue'),
    //     meta: {

    //         permissions: 49,
    //     },
    // },
    // {
    //     path: '/plataforma-credito/editar-estabelecimento/:id',
    //     name: 'novo-estabelecimento',
    //     component: () => import('@/views/pages/CreditPlatform/NewMerchant.vue'),
    //     meta: {

    //         permissions: 49,
    //     },
    // },
    // {
    //     path: '/plataforma-credito/detalhes-estabelecimento/:id',
    //     name: 'details-merchant',
    //     component: () => import('@/views/pages/CreditPlatform/MerchantDetails.vue'),
    //     meta: {

    //         permissions: 49,
    //     },
    // },
    // {
    //     path: '/plataforma-credito/optin/novo',
    //     name: 'details-merchant',
    //     component: () => import('@/views/pages/CreditPlatform/NewOptIn.vue'),
    //     meta: {

    //         permissions: 49,
    //     },
    // },
    // {
    //     path: '/plataforma-credito/optin/editar/:id',
    //     name: 'details-merchant',
    //     component: () => import('@/views/pages/CreditPlatform/NewOptIn.vue'),
    //     meta: {

    //         permissions: 49,
    //     },
    // },
    // {
    //     path: '/plataforma-credito/consulta',
    //     name: 'details-merchant',
    //     component: () => import('@/views/pages/CreditPlatform/NewConsult.vue'),
    //     meta: {

    //         permissions: 49,
    //     },
    // },
    // {
    //     path: '/plataforma-credito/consulta/:id',
    //     name: 'details-merchant',
    //     component: () => import('@/views/pages/CreditPlatform/NewConsult.vue'),
    //     meta: {

    //         permissions: 49,
    //     },
    // },
    // {
    //     path: '/plataforma-credito/ur/:id',
    //     name: 'details-merchant',
    //     component: () => import('@/views/pages/CreditPlatform/UrDetails.vue'),
    //     meta: {

    //         permissions: 49,
    //     },
    // },
    // {
    //     path: '/plataforma-credito/averbacao/novo',
    //     name: 'details-merchant',
    //     component: () => import('@/views/pages/CreditPlatform/Averbation/NewAverbation.vue'),
    //     meta: {

    //         permissions: 49,
    //     },
    // },
    // {
    //     path: '/plataforma-credito/averbacao/editar/:id',
    //     name: 'details-merchant',
    //     component: () => import('@/views/pages/CreditPlatform/Averbation/EditAverbation.vue'),
    //     meta: {

    //         permissions: 49,
    //     },
    // },
    // {
    //     path: '/plataforma-credito/alertas',
    //     name: 'alert-credit-platform',
    //     component: () => import('@/views/pages/CreditPlatform/Alerts/Alerts.vue'),
    //     meta: {

    //         permissions: 49,
    //     },
    // },
];

export default creditPlatformRouter;
