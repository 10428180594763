import metabaseRouter from './metabase';
import powerBiRouter from './power-bi';
import viaInvestRouter from './viainvest';

const reportsRouter = [
    ...metabaseRouter,
    ...powerBiRouter,
    ...viaInvestRouter,
];

export default reportsRouter;
