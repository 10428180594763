const transactionMonitoringRouter = [
    {
        path: '/monitoramento-transacoes',
        name: 'monitoring',
        component: () => import('@/views/pages/TransactionMonitoring/Alerts/Alerts.vue'),
        meta: {
            requiresAuth: true,
            permissions: 58,

        },
    },
    {
        path: '/monitoramento-transacoes/detalhes',
        name: 'details',
        component: () => import('@/views/pages/TransactionMonitoring/Details/Details.vue'),
        meta: {
            requiresAuth: true,
            permissions: 58,

        },
    },
    {
        path: '/monitoramento-transacoes/detalhes/:id',
        name: 'detailsById',
        component: () => import('@/views/pages/TransactionMonitoring/Details/Details.vue'),
        meta: {
            requiresAuth: true,
            permissions: 58,

        },
    },
    {
        path: '/monitoramento-transacoes/estabelecimentos/historico',
        name: 'merchant-monitoring-history',
        component: () => import('@/views/pages/TransactionMonitoring/MerchantMonitoring.vue'),
        meta: {
            requiresAuth: true,
            permissions: 58,

        },
    },
    {
        path: '/monitoramento-transacoes/parametros',
        name: 'merchant-monitoring-params',
        component: () => import('@/views/pages/TransactionMonitoring/Params/Params.vue'),
        meta: {
            requiresAuth: true,
            permissions: 58,

        },
    },
    {
        path: '/monitoramento-transacoes/painel',
        name: 'merchant-monitoring-dashboard',
        component: () => import('@/views/pages/TransactionMonitoring/Report.vue'),
        meta: {
            requiresAuth: true,
            permissions: 58,
        },
    },
];

export default transactionMonitoringRouter;
