export default {
  namespaced: true,
  state: {
    data: {},
  },
  getters: {
    dataUr: state => state,
  },
  mutations: {
    SET_UR(state, payload) {
      state.data = payload
    },
  },
  actions: {},
}
