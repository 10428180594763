import permissions from '@/enums/permissions';

export default [
    {
        path: '/relatorio-transacoes',
        name: 'transactions-report',
        component: () => import('@/views/pages/TransactionsReport/TransactionsReport.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.transactionReport,
        },
    },
];
