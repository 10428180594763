import permissions from '@/enums/permissions';

const viaInvestRouter = [
    {
        path: '/painel/calculadora-ccb',
        name: 'ccb-calculator-dashboard',
        component: () => import('@/views/pages/reports/ViaInvest/CCBCalculator.vue'),
        meta: {
            permissions: permissions.dashboardCCBCalculator,
        },
    },
    {
        path: '/painel/rentabilidade-de-clientes',
        name: 'customer-profitability-dashboard',
        component: () => import('@/views/pages/reports/ViaInvest/CustomerProfitability.vue'),
        meta: {
            permissions: permissions.customerProfitability,
        },
    },
];

export default viaInvestRouter;
