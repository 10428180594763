import permissions from '@/enums/permissions';

const filesRouter = [
    {
        path: '/upload',
        name: 'upload',
        component: () => import('@/views/pages/file/Upload.vue'),
        meta: {
            permissions: permissions.uploadVeripag,
        },
    },
    {
        path: '/arquivos/orcamento',
        name: 'upload-budget',
        component: () => import('@/views/pages/file/Budget.vue'),
        meta: {
            permissions: permissions.uploadBudget,
        },
    },
    {
        path: '/upload-rede',
        name: 'upload-rede',
        component: () => import('@/views/pages/file/UploadRede.vue'),
        meta: {
            permissions: permissions.uploadRede,
        },
    },
    {
        path: '/oportunidades',
        name: 'opportunities',
        component: () => import('@/views/pages/file/OpportunitiesRegistration.vue'),
        meta: {
            permissions: permissions.uploadOpportunuties,
        },
    },
    {
        path: '/arquivos/conciliacao',
        name: 'file-conciliation',
        component: () => import('@/views/pages/file/Conciliation.vue'),
        meta: {
            permissions: permissions.uploadFileConciliation,
        },
    },
    {
        path: '/entrada-de-conta',
        name: 'account-entry',
        component: () => import('@/views/pages/AccountEntry/AccountEntry.vue'),
        meta: {
            permissions: permissions.uploadAccountEntry,
        },
    },
    {
        path: '/armazenar-arvore',
        name: 'store-tree',
        component: () => import('@/views/pages/file/StoreTree.vue'),
        meta: {
            permissions: permissions.uploadStoreTree,
        },
    },
    {
        path: '/pendencia-financeira',
        name: 'financial-pendencies',
        component: () => import('@/views/pages/file/FinancialPendencies.vue'),
        meta: {
            permissions: permissions.uploadFinancialPendencies,
        },
    },
    {
        path: '/aportes-resgates',
        name: 'redemption-contribuition',
        component: () => import('@/views/pages/file/RedemptionContribuitions.vue'),
        meta: {
            permissions: permissions.uploadRedemptionContribuition,
        },
    },
    {
        path: '/arquivos/rentabilidate-comercial',
        name: 'file-rentability-comercial',
        component: () => import('@/views/pages/file/ComercialProfitability.vue'),
        meta: {
            permissions: permissions.fileComercialProfitability,
        },
    },
    {
        path: '/arquivos/aluguel-maquininha',
        name: 'file-terminal-rental',
        component: () => import('@/views/pages/file/TerminalRental.vue'),
        meta: {
            permissions: permissions.terminalRental,
        },
    },
];

export default filesRouter;
