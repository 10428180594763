/* eslint-disable no-underscore-dangle */
import methods from '@/enums/methods';

export default class API {
    _doRequest(fetchParams) {
        const {
            method, url, data, params, headers, config: restParams,
        } = fetchParams;
        const config = {
            method, url, data, params, headers, ...restParams,
        };
        const request = this.api(config);

        return request;
    }

    async fetch({
        url,
        data,
        params,
        method = methods.GET,
        headers,
        ...config
    }) {
        try {
            const promise = () => this._doRequest({
                url,
                data,
                params,
                method,
                headers,
                config,
            });
            return await promise();
        } catch ({ response }) {
            return response;
        }
    }
}
