export const callLoading = (activateLoading, transparentLoading = false) => {
  const loadingDiv = document.getElementById('loading-bg')

  if (transparentLoading) { loadingDiv.classList.add('transparent') } else { loadingDiv.classList.remove('transparent') }

  if (activateLoading) {
    loadingDiv.style.display = 'block'
    loadingDiv.style.position = 'fixed'
    loadingDiv.style.zIndex = 6
  } else { loadingDiv.style.display = 'none' }
}

export const removeDarkMode = () => {
  const elementWDarkLayout = document.getElementsByClassName('dark-layout')
  if (elementWDarkLayout.length > 0) { elementWDarkLayout[0].classList.remove('dark-layout') }
}
