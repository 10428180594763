import permissions from '@/enums/permissions';

const installmentsRouter = [
    {
        path: '/parcelas/atualizar-status',
        name: 'installments-status-update',
        component: () => import('@/views/pages/Installments/InstallmentsStatusUpdate.vue'),
        meta: {
            permissions: permissions.updateInstallmentsStatus,
        },
    },
];

export default installmentsRouter;
