import permissions from '@/enums/permissions';

export default [
    {
        path: '/plano-acao-corporativo/importar-arquivo',
        name: 'action-plan-corp',
        component: () => import('@/views/pages/CorpActionPlan/ImportFile.vue'),
        meta: {
            permissions: permissions.corporateActionPlan,
        },
    },
    {
        path: '/plano-acao-corporativo/painel',
        name: 'action-plan-corp-dashboard',
        component: () => import('@/views/pages/CorpActionPlan/Dashboard.vue'),
        meta: {
            permissions: permissions.corporateActionPlan,
        },
    },
];
