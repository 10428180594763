import { cnpj, cpf } from 'cpf-cnpj-validator'

export default taxId => {
  if (!taxId) return '-------'

  if (taxId.length === 11) {
    return cpf.format(taxId)
  } if (taxId.length === 14) {
    return cnpj.format(taxId)
  }

  return taxId
}
