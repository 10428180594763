import permissions from '@/enums/permissions';

const comissionReportRouter = [
    {
        path: '/relatorio/representantes',
        name: 'comission-report',
        component: () => import('@/views/pages/ComissionReport/ComissionReport.vue'),
        meta: {
            permissions: permissions.comissionReport,
        },
    },
    {
        path: '/relatorio/representantes/ajuste',
        name: 'report-adjust',
        component: () => import('@/views/pages/ComissionReport/AdjustTax.vue'),
        meta: {
            permissions: permissions.reportAdjust,
        },
    },
];

export default comissionReportRouter;
