import { callLoading } from '@/service/utilities';
import UserService from '@/service/user/user';
import MenuService from '@/service/menu';

export default {
    namespaced: true,
    state: {
        data: {},
    },
    getters: {
        user: state => state,
    },
    mutations: {
        SET_USER(state, payload) {
            state.data = payload;
        },

        SET_MENU(state, payload) {
            state.data.menu = payload;
        },
    },
    actions: {
        async fetch({ commit }) {
            callLoading(true, true);

            const { data, status } = await UserService.me();

            if (status === 200) {
                commit('SET_USER', data.user);
            }

            const { data: menuData } = await MenuService.index();

            commit('SET_MENU', menuData);
            // try {
            //     const { data } = await axios.get(`${BASE_URL}/users/me`);
            // } catch (err) {
            //     window.location.href = '/login';
            // }
            callLoading(false);
        },
    },
};
