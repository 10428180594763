import Vue from 'vue';
import Vuex from 'vuex';

import app from './app';
import appConfig from './app-config';
import merchant from './merchant';
import schedule from './osiris-schedule';
import ur from './ur';
import verticalMenu from './vertical-menu';
import user from './user';

// Modules

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        ur,
        merchant,
        schedule,
        user,
    },
    strict: process.env.DEV,
});
