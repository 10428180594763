import storeUser from '@/store/user';
import UserService from '@/service/user/user';

const LOGIN_PATH = '/login';
const HOME_PATH = '/';

class AuthMiddleware {
    // eslint-disable-next-line class-methods-use-this
    async actions(next, permissionsRoute) {
        const { status } = await UserService.authenticate();

        if (status !== 200) { return next(LOGIN_PATH); }

        if (permissionsRoute) {
            const { permissions: permissionsUser } = storeUser.state.data.profile;

            const hasPermission = permissionsUser.some(permission => (permission === permissionsRoute));

            if (hasPermission) {
                return next();
            }

            return next(LOGIN_PATH);
        }

        return next();
    }

    async execute(to, from, next) {
        if (!to.path === '/' && !sessionStorage.getItem('token')) next(LOGIN_PATH);
        if (to.matched.some(record => record.meta.permissions) || to.path === ('/')) {
            const { permissions } = to.matched[0].meta;
            await this.actions(next, permissions);
        } else { next(); }
    }
}

export default new AuthMiddleware();
