import permissions from '@/enums/permissions';

const taskRouter = [
    {
        path: '/base-conciliacao',
        name: 'base-concilication',
        component: () => import('@/views/pages/BaseConciliation/Task.vue'),
        meta: {
            permissions: permissions.tefTax,
        },
    },
];

export default taskRouter;
