export default {
  namespaced: true,
  state: {
    schedule: null,
  },
  getters: {
    schedule: state => state.schedule,
  },
  mutations: {
    SET_SCHEDULE(state, payload) {
      state.schedule = {
        ...payload,
      }
    },
  },
  actions: {},
}
