import permissions from '@/enums/permissions';

const chargebackRouter = [
    {
        path: '/chargeback',
        name: 'chargeback',
        component: () => import('@/views/pages/Chargeback/Chargeback.vue'),
        meta: {
            permissions: permissions.chargeback,
        },
    },
    {
        path: '/painel/chargeback',
        name: 'chargeback-report',
        component: () => import('@/views/pages/Chargeback/Report.vue'),
        meta: {
            permissions: permissions.dashboardChargeback,
        },
    },
    {
        path: '/chargeback/:id',
        name: 'chargeback-details',
        component: () => import('@/views/pages/Chargeback/Details.vue'),
        meta: {
            permissions: permissions.chargebackDetails,
        },
    },
];

export default chargebackRouter;
