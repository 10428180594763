import permissions from '@/enums/permissions';

const metabaseRouter = [
    {
        path: '/reports-cobranca',
        name: 'cobranca',
        component: () => import('@/views/pages/reports/Metabase/Charge.vue'),
        meta: {
            permissions: permissions.charge,
        },
    },
    {
        path: '/taxas-aluguel',
        name: 'rent-tax-dashboard',
        component: () => import('@/views/pages/reports/Metabase/RentTax.vue'),
        meta: {
            permissions: permissions.rentTax,
        },
    },
    {
        path: '/profile-ec-business',
        name: 'profile-ec-comercial',
        component: () => import('@/views/pages/reports/Metabase/ProfileEcBusiness.vue'),
        meta: {
            permissions: permissions.profileEcBusiness,
        },
    },
    {
        path: '/profile-ec-risk',
        name: 'profile-ec-risco',
        component: () => import('@/views/pages/reports/Metabase/ProfileEcRisk.vue'),
        meta: {
            permissions: permissions.profileEcRisk,
        },
    },
    {
        path: '/map-pos',
        name: 'map-pos',
        component: () => import('@/views/pages/reports/Metabase/MapPos.vue'),
        meta: {
            permissions: permissions.mapPos,
        },
    },
    {
        path: '/consult-transaction',
        name: 'consult-transaction',
        component: () => import('@/views/pages/reports/Metabase/ConsultTransaction.vue'),
        meta: {
            permissions: permissions.consultTransaction,
        },
    },
    {
        path: '/profile-group-business',
        name: 'profile-group-business',
        component: () => import('@/views/pages/reports/Metabase/ProfileGroupBusiness.vue'),
        meta: {
            permissions: permissions.profileGroupBusiness,
        },
    },
    {
        path: '/representantes',
        name: 'representatives',
        component: () => import('@/views/pages/reports/Metabase/Representative.vue'),
        meta: {
            permissions: permissions.representatives,
        },
    },
    {
        path: '/clientes-sem-transacionar',
        name: 'client-no-transaction',
        component: () => import('@/views/pages/reports/Metabase/ListClientNoTransaction.vue'),
        meta: {
            permissions: permissions.clientsWithoutTransaction,
        },
    },
    {
        path: '/antecipacao-de-transacoes',
        name: 'transaction-anticipation',
        component: () => import('@/views/pages/reports/Metabase/TransactionAnticipation.vue'),
        meta: {
            permissions: permissions.transactionAnticipation,
        },
    },
    {
        path: '/detalhes-risco',
        name: 'risk-details',
        component: () => import('@/views/pages/reports/Metabase/RiskDetails.vue'),
        meta: {
            permissions: permissions.riskDetails,
        },
    },
    {
        path: '/tpv',
        name: 'tpv',
        component: () => import('@/views/pages/reports/Metabase/TPV.vue'),
        meta: {
            permissions: permissions.tpv,
        },
    },
    {
        path: '/ec-por-tipo',
        name: 'ec-per-type',
        component: () => import('@/views/pages/reports/Metabase/EcPerType.vue'),
        meta: {
            permissions: permissions.ecPerType,
        },
    },
    {
        path: '/painel/ecommerce',
        name: 'ecommerce-dashboard',
        component: () => import('@/views/pages/reports/Metabase/Ecommerce.vue'),
        meta: {
            permissions: permissions.ecommerceDashboard,
        },
    },
    {
        path: '/painel/transacoes',
        name: 'transaction-report',
        component: () => import('@/views/pages/reports/Metabase/Transaction.vue'),
        meta: {
            permissions: permissions.dashboardTransaction,
        },
    },
    {
        path: '/painel/ticket-medio',
        name: 'mean-ticket-report',
        component: () => import('@/views/pages/reports/Metabase/MeanTicket.vue'),
        meta: {
            permissions: permissions.dashboardMeanTicket,
        },
    },
    {
        path: '/painel/mcc-validacao',
        name: 'mcc-validation',
        component: () => import('@/views/pages/reports/Metabase/MccValidation.vue'),
        meta: {
            permissions: permissions.dashboardMccValidation,
        },
    },
];

export default metabaseRouter;
