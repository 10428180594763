import permissions from '@/enums/permissions';

const userRouter = [
    {
        path: '/user/edit-user/:id',
        name: 'edit-user',
        component: () => import('@/views/pages/userBackOffice/EditUser.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.editUser,
        },
    },
    {
        path: '/profile/edit-profile/:id',
        name: 'edit-profile',
        component: () => import('@/views/pages/userBackOffice/EditProfile.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.editProfile,
        },
    },
    {
        path: '/profile/view-profile/:id',
        name: 'view-profile',
        component: () => import('@/views/pages/userBackOffice/ViewProfile.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.viewProfile,
        },
    },
    {
        path: '/novo-usuario',
        name: 'novo usuario',
        component: () => import('@/views/pages/userBackOffice/newUserBackOffice.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.newUser,

        },
    },
    {
        path: '/usuarios',
        name: 'usuarios',
        component: () => import('@/views/pages/userBackOffice/BackOfficeUser.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.users,
            KeepAlive: true,
        },
    },
    {
        path: '/novo-perfil',
        name: 'novo-perfil',
        component: () => import('@/views/pages/userBackOffice/NewProfile.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.newProfile,
        },
    },
    {
        path: '/novo-whatsapp-perfil',
        name: 'new-whatsapp-profile',
        component: () => import('@/views/pages/userBackOffice/NewWhatsappProfile.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.newProfile,
        },
    },

    {
        path: '/whatsapp-perfil/:id',
        name: 'edit-whatsapp-profile',
        component: () => import('@/views/pages/userBackOffice/EditProfileWhatsapp.vue'),
        meta: {
            requiresAuth: true,
            permissions: permissions.newProfile,
        },
    },
];

export default userRouter;
