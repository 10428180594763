import permissions from '@/enums/permissions';

export default [
    {
        path: '/rentabilidade-clientes',
        name: 'clients-rentability-tax',
        component: () => import('@/views/pages/ClientsRentability/ClientRentability.vue'),
        meta: {
            permissions: permissions.clientsRentability,
        },
    },
];
