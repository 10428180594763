import permissions from '@/enums/permissions';

const dashboardRouter = [
    {
        path: '/dashboard/blacklist',
        name: 'blacklist-dashboard',
        component: () => import('@/views/pages/Dashboard/BlackList.vue'),
        meta: {
            permissions: permissions.customerManagerDashboardBlacklist,
        },
    },

    {
        path: '/dashboard/client-sacker',
        name: 'list-client-sacker',
        component: () => import('@/views/pages/Dashboard/ClientSacker.vue'),
        meta: {
            permissions: permissions.customerManagerDashboardClientSacker,
        },
    },

    {
        path: '/dashboard/token',
        name: 'token-dashboard',
        component: () => import('@/views/pages/Dashboard/SendToken.vue'),
        meta: {
            permissions: permissions.customerManagerDashboardToken,
        },
    },
];

export default dashboardRouter;
