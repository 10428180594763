import permissions from '@/enums/permissions';

export default [
    {
        name: 'ntk',
        path: '/ntk',
        component: () => import('@/views/pages/NTK/NTK.vue'),
        meta: {
            permissions: permissions.ntk,
        },
    },
];
